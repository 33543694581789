<style src="./mobile-numbers-list.component.scss" lang="scss"></style>

<template>
  <div class="mobile-numbers-list d-block d-md-none">
    <v-card
        class="mb-20"
        v-for="(item , index) in mobileNumbersList" :key="index">
      <div class="information">
        <div class="information__title">
          {{ locale.phone }}
        </div>
        <div class="information__description">
          {{definePrefix(item.number) }} {{defineNumber(item.number)}}
        </div>
      </div>
      <div class="information">
        <div class="information__title">
          {{ locale.operator }}
        </div>
        <div class="information__description">
          {{ item.operator }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script src="./mobile-numbers-list.component.ts"></script>
