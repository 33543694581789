import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class ListMobileNumbersSandbox {
    private store = store;

    constructor() {
    }

    getMobileNumbersList(payload: any): void {
        this.store.dispatch('getMobileNumbersList', payload);
    }
    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }
    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }
}