import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'mobile-numbers-list-component',
    props: {
        mobileNumbersList: {
            type: Array,
        }
    },
})

export default class MobileNumbersListComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public pageNumber = 1;
    public pageSize = 10;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.localTalks) {
                this.locale = res.listMobileNumbers;
            }
        });
    }
    public determineNumber(index: number) {
        if (this.pageNumber === 1) {
            return index + 1;
        } else if (this.pageNumber > 1) {
            return (((this.pageNumber - 1) * this.pageSize) + (index + 1));
        }
    }

    public definePrefix(number:any) {
        return `0${number.slice(0,2)}`
    }
    public defineNumber(number:any) {
        let phone  = number.slice(-7)
        return `${phone[0]}${phone[1]}${phone[2]} ${phone[3]}${phone[4]} ${phone[5]}${phone[6]}`
    }

}
