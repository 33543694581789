<style src="./mobile-numbers-table.component.scss" lang="scss"></style>

<template>
  <div class="mobile-numbers-table">
    <table-component>
      <thead>
      <tr>
        <th class="">{{ locale.number }}</th>
        <th>{{ locale.prefix }}</th>
        <th>{{ locale.phone }}</th>
        <th>{{ locale.operator }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in mobileNumbersList" :key="index">
        <th>{{ determineNumber(index) }}</th>
        <th>{{ definePrefix(item.number) }}</th>
        <th class="local-table-card__number">{{ defineNumber(item.number) }}</th>
        <th>{{ item.operator }}</th>
      </tr>
      </tbody>
    </table-component>
  </div>
</template>

<script src="./mobile-numbers-table.component.ts"></script>
