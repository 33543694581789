<style src="./list-mobile-numbers-service.component.scss" lang="scss"></style>

<template>
  <div class="list-mobile-numbers-service">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
          <section>
            <customer-info-component :user="userData"/>
          </section>
          <section class="mt-30 mb-100">
            <v-card  class="h-container d-none d-md-block">
              <!--              <services-subtitle-component>-->
              <!--                {{ locale.header }}-->
              <!--              </services-subtitle-component>-->
              <mobile-numbers-table-component
                  v-if="mobileNumbersList.length"

                  :mobileNumbersList="mobileNumbersList"/>
            </v-card>
            <mobile-numbers-list-component
                v-if="mobileNumbersList.length"
                class="h-container d-block d-md-none"
                :mobileNumbersList="mobileNumbersList"/>
            <v-card v-if="(mobileNumbersList.length === 0) && userData.name">
              <not-data-component class="mt-20" v-if="(mobileNumbersList.length === 0) && userData.name">
                {{ localeMessage.notData }}
              </not-data-component>
            </v-card>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./list-mobile-numbers-service.component.ts"></script>
