import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import {IGetNumber} from '@/pages/local-talks-service/types/get-number';
import {IUser} from '@/core/auth/store/state';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {ListMobileNumbersSandbox} from '@/pages/list-mobile-numbers-service/sandbox/list-mobile-numbers.sandbox';
import MobileNumbersTableComponent
    from '@/pages/list-mobile-numbers-service/components/mobile-numbers-table/mobile-numbers-table.component.vue';
import ServiceSubtitleComponent from '@/core/shared/service-subtitle/service-subtitle.component.vue';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import MobileNumbersListComponent
    from '@/pages/list-mobile-numbers-service/components/mobile-numbers-list/mobile-numbers-list.component.vue';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'list-mobile-numbers-service-component',
    components: {
        CustomerInfoComponent,
        ServiceNameComponent,
        NotDataComponent,
        MobileNumbersTableComponent,
        ServiceSubtitleComponent,
        MobileNumbersListComponent
    }
})

export default class ListMobileNumbersServiceComponent extends mixins(Router)  {
    public service = 1074;
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    public sandbox = container.resolve(ListMobileNumbersSandbox);
    public locale$: any = locale;
    public locale: any = null;
    public localeMessage: any = null;
    private store = store;
    public pageNumber = 1;
    public pageSize = 10;
    public select = 0;


    get userData(): IUser {
        if (localStorage.getItem('mainToken')) {
            this.sandbox.getMobileNumbersList(localStorage.getItem('mainToken'));
            this.getServiceData();
        }
        return store.getters._getUserFullData;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get mobileNumbersList(): IGetNumber[] {
        return this.store.getters._getMobileNumbersList;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.registrationMobile) {
                this.locale = res.listMobileNumbers;
                this.localeMessage = res.message;
            }
        });
        if (localStorage.getItem('mainToken')) {
            this.sandbox.getMobileNumbersList(localStorage.getItem('mainToken'));
        }
        // this.sandbox.getMobileNumbersList(localStorage.getItem('mainToken'));
    }

    public changePage(info: any) {
        let payload = {
            token: localStorage.getItem('mainToken'),
            pageSize: info.pageSize,
            pageNumber: info.pageNumber,
        };
        this.sandbox.getMobileNumbersList(payload);
    }

}
